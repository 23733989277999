import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import agriculture from '../images/agriculture.jpeg';
import b2g from '../images/b2g.jpeg';
import industrial_products from '../images/industrial_products.jpeg';
import digital_1 from '../images/digital_1.jpeg';
import digital_2 from '../images/digital_2.jpeg';
import digital_3 from '../images/digital_3.jpeg';
import collectables_1 from '../images/collectables_1.jpeg';
import collectables_2 from '../images/collectables_2.jpeg';
import collectables_3 from '../images/collectables_3.jpeg';
import collectables_4 from '../images/collectables_4.jpeg';
import collectables_5 from '../images/collectables_5.jpeg';
import collectables_6 from '../images/collectables_6.jpeg';
import collectables_7 from '../images/collectables_7.jpeg';
import collectables_8 from '../images/collectables_8.jpeg';
import collectables_9 from '../images/collectables_9.jpeg';

const wallpaperData = [
  {
    title: `Collectables - Precious Metals - Patented CPG & Technology`,
    images: [collectables_1, collectables_2, collectables_3, collectables_4, collectables_5, collectables_6, collectables_7, collectables_8, collectables_9],
    description: `MCG acquires , and sources an array of artwork, collectable merchandise, precious metals for future decade asset appreciation , resale , and financially structured instruments yielding opportunities against such assets.`,
    details: [],
    showDiscoverButton: false,
    discoverLink: '/collectables',
  },
  {
    title: `Digital -  IP Division`,
    images: [digital_1, digital_2, digital_3],
    description: `MCG's Digital and IP Division specializes in acquiring and licensing branded offline-to-online (OTO) intellectual property (IP) rights within the film entertainment , musical, and professional sports sectors . 
    \nThe division also operates on a licensing model, distributing IP digital rights across major social, OTT ,gaming software, consumer wearables, Web 3.0 platforms on a case by case basis.`,
    details: [],
    showDiscoverButton: false,
    discoverLink: '/digital',
  },
  {
    title: `B2G - PPP - Bilateral Trade`,
    images: [b2g],
    description: `MCG, from time to time and sector dependent operates in country-to-country bilateral trade arrangements working with all key stakeholders for economic synergistic GDP growth and key market sector developments. Key objectives include but is not limited too:
`,
    details: [
      { label: '1', value: '⁠Bilateral Trade Agreements' },
      { label: '2', value: 'Private and Public Partnerships' },
      { label: '3', value: 'Sector Development' },
      { label: '4', value: 'Key Investment Promotion' },
      { label: '5', value: 'Stakeholder Engagement' },
      { label: '6', value: 'Market Research and Analysis' },
      { label: '7', value: '⁠Deal Origination and Execution' },
    ],
    showDiscoverButton: false,
    discoverLink: '/b2g',
  },
  {
    title: `Agriculture - Food & Beverage`,
    images: [agriculture],
    description: `The business division of global licensing of food and beverage commodities, importation and exportation of agriculture commodities, spices, ready made beverage goods.
    \nThrough MCG  global confidential strategic business partners’, vendors’, buyers’, clients’, suppliers’, authorized agents’, and business relationships; the organization aims to provide top tier curated products and brands into global marketplaces and developing markets.`,
    details: [],
    showDiscoverButton: true,
    discoverLink: '/agriculture',
  },
  {
    title: `Industrial Products`,
    images: [industrial_products],
    description: `MCG industrial division primarily focuses on export of BC Lumber to strategic portfolio clients , countries , commodity traders . The division also works within Canada for sourcing raw industrial materials for infrastructure projects on a case by case basis.`,
    details: [],
    showDiscoverButton: true,
    discoverLink: '/industrial-products',
  },
];

const DiscoverProductsButton = ({ linkUrl }) => {
  return (
    <div className="flex justify-center my-1">
      <Link
        to={linkUrl}  // <--- dynamic route
        className="text-white bg-[#4695CE] hover:bg-[#53B18D] inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl"
      >
        Discover products
        <svg
          className="w-4 h-4 ml-1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 
            010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 
            11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 
            0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
      </Link>
    </div>
  );
};

const Wallpaper = ({
  title,
  images,        // This is an array of images
  description,
  details,
  showDiscoverButton,
  discoverLink
}) => {
  // Track which image in the array is currently displayed
  const [imageIndex, setImageIndex] = useState(0);
 // Whether to show the image (true = fully opaque; false = faded out)
 const [showImage, setShowImage] = useState(true);

  useEffect(() => {
    // Only cycle if there's more than 1 image
    if (images.length > 1) {
      const interval = setInterval(() => {
        // 1) Trigger a fade-out
        setShowImage(false);
        
        // 2) After half a second, switch to next image and fade back in
        setTimeout(() => {
          setImageIndex((prev) => (prev + 1) % images.length);
          setShowImage(true);
        }, 500); // match the duration-500 class

      }, 3000); // change image every 3 seconds

      // Cleanup
      return () => clearInterval(interval);
    }
  }, [images]);

  return (
    <div className="relative bg-white rounded-lg shadow-2xl overflow-hidden group hover:scale-105 transition-transform duration-300 ease-in-out">
      
      {/* Card image & Title */}
      <img
        // Access the current image by imageIndex
        src={images[imageIndex]}
        alt={title}
        className="w-full h-auto"
      />
      <h4 className="font-semibold text-lg md:text-2xl text-center my-4">
        {title}
      </h4>

      {/* Overlay that appears on hover */}
      <div
        className="
          absolute inset-0
          bg-white
          bg-opacity-90
          flex flex-col
          px-4 py-6
          opacity-0
          group-hover:opacity-100
          transition-opacity
          duration-300
          ease-in-out
          overflow-y-auto
          h-full        /* So it takes the full card height */
        "
      >
        {/* Description */}
        <p className="text-base md:text-base lg:text-xl text-justify text-gray-700">
          {description.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </p>

        {/* Details section if any */}
        {details?.length > 0 && (
          <div className="pt-5 text-base md:text-base lg:text-xl  text-gray-700">
            {details.map((detail, index) => (
              <p key={index}>
                <span className="font-black">{detail.label}.</span>{' '}
                {detail.value}
              </p>
            ))}
          </div>
        )}

        {/* Conditionally show the button */}
        {showDiscoverButton && (
          <div className="mt-5">
            <DiscoverProductsButton linkUrl={discoverLink} />
          </div>
        )}
      </div>
    </div>
  );
};

const Wallpapers = () => {
  return (
    <>
      <div className="my-4 py-4" id="products">
        <h2 className="my-2 text-center text-3xl primary-text uppercase font-bold">
          Our Products
        </h2>
        <div className="flex justify-center">
          <div className="w-24 border-b-4 border-[#4695CE] mb-8"></div>
        </div>
        <div className="px-4" data-aos="fade-down" data-aos-delay="600">
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5">
            {wallpaperData.map((wallpaper, index) => (
              <Wallpaper key={index} title={wallpaper.title} images={wallpaper.images} description={wallpaper.description} details={wallpaper.details} showDiscoverButton={wallpaper.showDiscoverButton} discoverLink={wallpaper.discoverLink}/>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Wallpapers;
